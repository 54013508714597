const updateProperties = (elem, state, e) => {
    elem.style.setProperty('scale', `none`)
    elem.style.setProperty('rotate', `none`)
    elem.style.setProperty('transform', `translate(-50%, -50%) translate(${e.screenX}px, ${e.screenY - 100}px)`)
  }
  
  document.querySelectorAll('.cursor1, .cursor2').forEach(cursor => {
    let onElement
  
    const createState = e => {
      const defaultState = {
      }
  
      const computedState = {}
  
      if (onElement != null) {
        const { top, left, width, height } = onElement.getBoundingClientRect()
        const radius = window.getComputedStyle(onElement).borderTopLeftRadius
  
        computedState.x = left + width / 2
        computedState.y = top + height / 2
        computedState.width = width
        computedState.height = height
        computedState.radius = radius
      }
  
      return {
        ...defaultState,
        ...computedState
      }
    }
  
    document.addEventListener('mousemove', e => {
      const state = createState(e)
      updateProperties(cursor, state, e)
    })
  
    document.querySelectorAll('a, button').forEach(elem => {
      elem.addEventListener('mouseenter', () => (onElement = elem))
      elem.addEventListener('mouseleave', () => (onElement = undefined))
    })
  })
  