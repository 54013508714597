$('#play-video').on('click', function(e){
    const id = $(this).data('video-id');
    const embed = 'https://www.youtube.com/embed/' + id;
    e.preventDefault();
    $('#video-overlay').addClass('open');
    $("#video-overlay").append('<iframe class="video" src="'+ embed +'" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" frameborder="0" allowfullscreen></iframe>');
});

$('.video-overlay, .video-overlay-close').on('click', function(e){
    e.preventDefault();
    close_video();
});

$(document).keyup(function(e){
    if(e.keyCode === 27) { close_video(); }
});

function close_video() {
    $('.video-overlay.open').removeClass('open').find('iframe').remove();
};