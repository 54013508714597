var detectOs = {
    getUserAgent: function () {
        return navigator.userAgent;
    },
    getPlatform: function () {
        return navigator.platform;
    },
    isIos: function () {
        return /iPhone|iPad|iPod/.test(detectOs.getPlatform());
    },
    isAndroid: function () {
        return /Android/.test(detectOs.getUserAgent());
    },
    isBlackBerry: function () {
        return /BlackBerry/.test(detectOs.getPlatform());
    },
    isMac: function () {
        return /Mac/.test(detectOs.getPlatform());
    },
    isWindows: function () {
        return /Win/.test(detectOs.getPlatform());
    },
    isLinux: function () {
        return /Linux/.test(detectOs.getPlatform()) && !detectOs.isAndroid();
    },
    get: function () {
        if (detectOs.isIos()) {return 'iOS';}
        if (detectOs.isAndroid()) {return 'Android';}
        if (detectOs.isBlackBerry()) {return 'BlackBerry';}
        if (detectOs.isMac()) {return 'Mac';}
        if (detectOs.isWindows()) {return 'Windows';}
        if (detectOs.isLinux()) {return 'Linux';}
        return 'Unknown';
    },
};

let detectClick = (e) => {
    let url = e.target.href;
    var mobile = e.target.dataset?.mobile;
    var appName = mobile?.toString()?.split(':');
    // set app name firs of array
    if(Array.isArray(appName) && appName.length > 1 ) {
        appName = appName[0];
    }   
    // თუ მითითებული არ არის ან ცარიელია დაიკიდებს
    if( typeof mobile === 'undefined' || mobile?.trim() === '' ) {
        return e;
    }
    // if its mobile
    if( detectOs.isAndroid() || detectOs.isIos() ) {
        e.preventDefault();
        // ios for facebook } Not work page and need to transfer profile...
        if(appName === 'fb' && detectOs.isIos()) {
            mobile = mobile.replace('page', 'profile')
        }
        // this turn app
        document.location = mobile;
        return false;
    }
    return e;
}
	
document.querySelectorAll('a[data-mobile]').forEach( a => {
    a.addEventListener('click', detectClick)
})