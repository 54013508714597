window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      console.log('This page was restored from the bfcache.');
    } else {
      console.log('This page was loaded normally.');
    }
});
//
window.addEventListener('pagehide', (event) => {
    if (event.persisted) {
      console.log('This page *might* be entering the bfcache.');
    } else {
      console.log('This page will unload normally and be discarded.');
    }
});
/**
 * Javascript modules and plugins
 */
import "./js/cursor";
import "./js/main";
import "./js/nav01";
import "./js/firework-video-play";
import "./js/open-facebook-links-in-facebook-app";


document.addEventListener("DOMContentLoaded", function() {
	const lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

	if ("IntersectionObserver" in window) {
		let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
		entries.forEach(function(entry) {
			if (entry.isIntersecting) {
				let lazyImage = entry.target;
				lazyImage.src = lazyImage.dataset.src ?? '';
				lazyImage.srcset = lazyImage.dataset.srcset ?? '';
                //
                delete lazyImage.dataset.src;
                delete lazyImage.dataset.srcset;
                //
				lazyImage.classList.remove("lazy");
				lazyImageObserver.unobserve(lazyImage);
			}
		});
		});

		lazyImages.forEach(function(lazyImage) {
		lazyImageObserver.observe(lazyImage);
		});
	} else {
		// Possibly fall back to event handlers here
	}
});

new PerformanceObserver((list) => {
const latestEntry = list.getEntries().at(-1);

if (latestEntry?.element?.getAttribute('loading') == 'lazy') {
    console.warn('Warning: LCP element was lazy loaded', latestEntry);
}
}).observe({type: 'largest-contentful-paint', buffered: true});
